import React from 'react';
import { Link } from "gatsby"
import './assets/styles/_index.scss';
import { ShowProcessedImage } from "../common/stb-jamstack-ggfxclient/module/components/image/show-image"
import imageConfig from "../../../static/images/config.json"
import NoImg from "../../images/no-image.png"
import { getWhatsAppURL } from '../../site/utils';
import { registerInterestURL } from '../../site/urls';
import GetGGFXImage from '../common/site/get-ggfx-image';
import { ImageModule } from "../common/Image_Module"

const OffplanPropertyCard = (props) => {
    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.images_Transforms) {
        processedImages = props.imagetransforms.images_Transforms;
    }

    const phoneNoReplace = (phone) => {
        return phone && phone.replace('+971 543239494','+971 55 867 7338') || false;
    }
    let propertyNego_phone = phoneNoReplace(props.propertyNego?.phone);

    return (
        <div className="property-card-wrapper off-plan">
            <div className="property-card-img-zoom">
                {
                    props?.img && props?.img.length > 0 ?
                    <Link to={props.propertyDetailsLink}>
                        {props.normalImg ? 
                        // <img src={props?.img[0].url} alt={props.propertyAddress + " - White & Co Real Estate"} />
                        <ImageModule
                            imagename={"offplan.images.featuredProperties"}
                            ImageSrc={props?.img[0]}
                            ggfx_results={props?.ggfx_results}
                            altText={props.propertyAddress + " - White & Co Real Estate"}
                            strapi_id={props?.id}
                        />
                         : <ShowProcessedImage
                            images={props?.img && props?.img[0]}
                            attr={{
                                className: "img-fluid",
                                alt: props.propertyAddress + " - White & Co Real Estate",
                            }}
                            transforms={imageConfig.property.searchResults.sizes}
                        />}
                    </Link>
                    :
                    <Link to={props.propertyDetailsLink}>
                        <img src={NoImg} alt="no image" />
                    </Link>
                }
            </div>
            <div className="property-card-text-wrapper">
                {
                    props.propertyDescription &&
                    <p className="display-address display-desc">{props.propertyDescription}</p>
                }
                {
                    props.propertyAddress &&
                    <Link to={props.propertyDetailsLink}>
                        <div className="price-details">{props.propertyAddress}</div>
                    </Link>
                }
                {
                    props.proeprtyArea &&
                    // <div className="location-details off-plan d-flex align-items-center">
                    <div className="location-details off-plan d-flex">
                        <i className="icon icon-map-pin"></i>
                        <span>{props.proeprtyArea}</span>
                    </div>
                }
                <ul className="off-plan-list">
                    {
                        props.propertyPrice &&
                        <li className="list-details">From {props.propertyCurrency || "AED"} {props.propertyPrice.toLocaleString()}</li>
                    }
                    {
                        props.propertyTitle &&
                        <li className="list-details">{props.propertyTitle}</li>
                    }
                    <li className="list-details">Q4 - 2024 Handover</li>
                </ul>
                {!props.isRegisterForm ? <ul className="list-inline off-plan-contact-list d-flex align-items-center">
                    <li className="list-inline-item">
                        <Link to={`${registerInterestURL}?pid=${props?.id}`} className="d-flex align-items-center">
                            <i className="icon icon-team-email"></i>
                            <span>Enquire</span>
                        </Link>
                    </li>
                    {
                        propertyNego_phone &&
                        <li className="list-inline-item hovericon">
                            <Link href={"tel:" + propertyNego_phone}><span>{propertyNego_phone}</span></Link>
                        </li>
                    }
                    {
                        propertyNego_phone &&
                        <li className="list-inline-item">
                            <a href={getWhatsAppURL(propertyNego_phone)} target="_blank" className="d-flex align-items-center">
                                <i className="icon icon-team-whatsapp"></i>
                                <span>WhatsApp</span>
                            </a>
                        </li>
                    }
                </ul> : ""}
            </div>
        </div>
    )
}

export default OffplanPropertyCard